<section class="w3l-banner-slider">
    <header id="headers4-block">
        <div class="wrapper">
            <div class="d-grid nav-mobile-block header-align">
                <div class="logo">
                    <a class="brand-logo" [routerLink]="['/home']">
                        <img src="./assets/images/logo/Malwa_Green_Logo_CDR_revised.png" alt="malwa greens"
                            title="malwa greens" height="50px;"/>
                    </a>
                </div>
                <input type="checkbox" id="nav" />
                <label aria-hidden="true" class="nav" for="nav"></label>
                <nav>
                    <label for="drop" class="toggle nav-height">Navigation<span class="fa fa-bars"
                        aria-hidden="true"></span></label>
                    <input type="checkbox" id="drop">
                    <ul class="menu mr-auto">
                        <li><a aria-hidden="true" (click)="isNavbarCollapsed = true" [routerLink]="['/home']">Home</a></li>
                        <li><a aria-hidden="true" [routerLink]="['/about-us']" >About</a></li>
                        <li><a aria-hidden="true" [routerLink]="['/services']" >Services</a></li>
                        <li><a aria-hidden="true" [routerLink]="['/farming-techniques']" >Farming Practices</a></li>
                        <li><a aria-hidden="true" [routerLink]="['/achievements']" >Achievements</a></li>
                        <li><a aria-hidden="true" [routerLink]="['/our-impact']" >Our Impact</a></li>
                        <li><a aria-hidden="true" [routerLink]="['/media']" >Media</a></li>
                        <li><a aria-hidden="true" [routerLink]="['/contact-us']" >Contact Us</a></li>
                    </ul>
                </nav>
            </div>
            <div class="d-grid nav-mobile-block header-align">
            </div>
        </div>
    </header>
</section>

<router-outlet>

</router-outlet>

<section class="w3l-footer-22">
    <!-- footer-22 -->
    <div class="footer">
        <div class="footer-wrapper">
            <div class="footer-tophny">
                <div class="left-side">
                    <h2 class=" text-center"><a [routerLink]="['/home']" class="footer-logo "><img
                                src="./assets/images/logo/Malwa_Green_Logo_CDR_white.png" alt="malwa greens"
                                title="malwa greens" height="70px" /></a>
                    </h2>
                    <p>Our<b> Agri Pandit </b> advise on Market trends & Weather Forecast, Our <b>Hi Tech
                            Technologies</b> will help to serve Agricultural Science with Innovation, Our <b>Agri
                            Economist</b> will provide viable returns with effective cost management,<b>Our
                            Professional Team</b> deliver trust with ethics.</p>
                    <h5 class="text-center text-white">We are Family of Experienced Hands.</h5>
                    <h4 class="text-center text-white">WE ARE MALWA GREENS.</h4>
                </div>

                <div class="sub-one-left">
                    <h6>Menu Links</h6>
                    <ul class="list-footer">
                        <li><a [routerLink]="['/home']"><span class="fa fa-angle-double-right"
                                    aria-hidden="true"></span>Home</a></li>
                        <li><a [routerLink]="['/about-us']"><span class="fa fa-angle-double-right"
                                    aria-hidden="true"></span>About</a></li>
                        <li><a [routerLink]="['/services']"><span class="fa fa-angle-double-right"
                                    aria-hidden="true"></span>Services</a></li>
                        <li><a [routerLink]="['/farming-techniques']"><span class="fa fa-angle-double-right"
                                    aria-hidden="true"></span>Farming Practices</a></li>
                        <li><a [routerLink]="['/achievements']"><span class="fa fa-angle-double-right"
                                    aria-hidden="true"></span>Achievements</a></li>
                        <li><a [routerLink]="['/our-impact']"><span class="fa fa-angle-double-right"
                                    aria-hidden="true"></span>Our Impact </a></li>
                        <li><a [routerLink]="['/media']"><span class="fa fa-angle-double-right"
                                    aria-hidden="true"></span>Media</a></li>
                        <li><a [routerLink]="['/contact-us']"><span class="fa fa-angle-double-right"
                                    aria-hidden="true"></span>Contact Us</a></li>

                    </ul>
                </div>

                <div class="sub-one-left">
                    <h6>Important Links</h6>
                    <ul class="list-footer">
                        <li> <a target="blank" href="https://www.icar.org.in/ "><span class="fa fa-angle-double-right"
                                    aria-hidden="true"></span>https://www.icar.org.in/ </a>
                        </li>
                        <li> <a target="blank" href="http://nhb.gov.in/"><span class="fa fa-angle-double-right"
                                    aria-hidden="true"></span>http://nhb.gov.in/ </a>
                        </li>
                        <li> <a target="blank" href="https://www.mpdage.org/ "><span class="fa fa-angle-double-right"
                                    aria-hidden="true"></span>https://www.mpdage.org/ </a>
                        </li>
                        <li> <a target="blank" href="http://www.mphorticulture.gov.in/en/important-links "><span
                                    class="fa fa-angle-double-right"
                                    aria-hidden="true"></span>http://www.mphorticulture.gov.in </a>
                        </li>
                        <li> <a target="blank" href="http://www.mpwrd.gov.in/ "><span class="fa fa-angle-double-right"
                                    aria-hidden="true"></span>http://www.mpwrd.gov.in/ </a>
                        </li>

                        <li> <a target="blank" href="https://apeda.gov.in/apedawebsite/"><span
                                    class="fa fa-angle-double-right" aria-hidden="true"></span>https://apeda.gov.in</a>
                        </li>

                    </ul>
                </div>
            </div>

            <div class="below-section">
                <div class="columns">
                    <ul class="jst-link">
                        <ul class="social">
                            <li><a href="https://www.facebook.com/shipendra" target="blank"> <span
                                        class="fa fa-facebook"></span></a></li>
                            <li><a href="#" target="blank"><span class="fa fa-twitter"></span></a></li>
                            <li><a href="#" target="blank"><span class="fa fa-youtube"></span></a></li>
                            <li><a href="https://instagram.com/malwagreens?igshid=13b7awlgacdqo" target="blank"><span
                                        class="fa fa-instagram"></span></a></li>

                        </ul>
                    </ul>
                </div>

                <div class="columns">
                    <p class="copy-right-hny">© 2021. All Rights Reserved Developed and Maintained By <br><a
                            target="blank" href="https://www.amstechinc.com/">Amstech
                            Incorporation Private Limited</a></p>
                    <p class="copy-right-hny"></p>
                </div>

            </div>
        </div>
    </div>
    <!-- //titels-5 -->

    <!-- move top -->
    <button onclick="topFunction()" id="movetop" title="Go to top">
        <span class="fa fa-angle-up"></span>
    </button>

</section>