import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { LayoutComponent } from './layout/layout.component';
import { HomeComponent } from './web-pages/home/home.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./web-pages/home/home.module').then(m => m.HomeModule),
        pathMatch: 'full'
      },
      {
        path: 'home',
        loadChildren: () => import('./web-pages/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'about-us',
        loadChildren: () => import('./web-pages/about-us/about-us.module').then(m => m.AboutUsModule)
      },
      {
        path: 'services',
        loadChildren: () => import('./web-pages/services/services.module').then(m => m.ServicesModule)
      },
      {
        path: 'farming-techniques',
        loadChildren: () => import('./web-pages/farming-techniques/farming-techniques.module').then(m => m.FarmingTechniquesModule)
      },
      {
        path: 'achievements',
        loadChildren: () => import('./web-pages/achievements/achievements.module').then(m => m.AchievementsModule)
      },
      {
        path: 'media',
        loadChildren: () => import('./web-pages/media/media.module').then(m => m.MediaModule)
      },
      {
        path: 'faq',
        loadChildren: () => import('./web-pages/faq/faq.module').then(m => m.FaqModule)
      },
      {
        path: 'our-impact',
        loadChildren: () => import('./web-pages/our-impact/our-impact.module').then(m => m.OurImpactModule)
      },

      {
        path: 'contact-us',
        loadChildren: () => import('./web-pages/contact-us/contact-us.module').then(m => m.ContactUsModule)
      },
    ]
  },


 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
